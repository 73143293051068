<template>
  <div class="sider">
    <div class="logo">
      <img
        class="logo_img"
        src="../assets/logo_Y.png"
        alt=""
      >
      <span class="logo_text">博客后台管理系统</span>
    </div>
    <div class="menu">
      <a-menu
        :openKeys="$store.state.openKeys"
        :selectedKeys="$store.state.selectedKeys"
        style="width: 100%"
        mode="inline"
        theme="dark"
        @click="clickMenu"
      >
        <a-sub-menu key="/article">
          <template #icon>
            <FileTextOutlined />
          </template>
          <template #title>文章管理</template>
          <a-menu-item key="/article/articleList">文章列表</a-menu-item>
          <a-menu-item key="/article/addArticle">添加文章</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/category">
          <template #icon>
            <HddOutlined />
          </template>
          <template #title>分类管理</template>
          <a-menu-item key="/category/categoryList">分类列表</a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="/tag">
          <template #icon>
            <TagOutlined />
          </template>
          <template #title>标签管理</template>
          <a-menu-item key="/tag/tagList">标签列表</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/message">
          <template #icon>
            <FormOutlined />
          </template>
          <template #title>信息管理</template>
          <a-menu-item key="/message/commentList">评论列表</a-menu-item>
          <a-menu-item key="/message/leaveWordList">留言列表</a-menu-item>
          <a-menu-item key="/message/likeList">点赞列表</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/tools">
          <template #icon>
            <ToolOutlined />
          </template>
          <template #title>工具管理</template>
          <a-menu-item key="/tools/toolsList">工具列表</a-menu-item>
          <a-menu-item key="/tools/toolsCategoryList">工具分类列表</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/love">
          <template #icon>
            <HeartOutlined />
          </template>
          <template #title>爱的管理</template>
          <a-menu-item key="/love/loveRecordList">爱的故事列表</a-menu-item>
          <a-menu-item key="/love/timeMachineList">时光机列表</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
  </div>
</template>

<script>
import {
  FileTextOutlined,
  FormOutlined,
  TagOutlined,
  HddOutlined,
  ToolOutlined,
  HeartOutlined
} from '@ant-design/icons-vue'
import router from '@/router'
export default {
  components: {
    FileTextOutlined,
    FormOutlined,
    TagOutlined,
    HddOutlined,
    ToolOutlined,
    HeartOutlined
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    function clickMenu(menuInfo) {
      router.push(menuInfo.key)
    }
    return {
      clickMenu
    }
  }
}
</script>

<style scoped>
.sider {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.logo {
  height: 64px;
  background: #001529;
  display: flex;
  align-items: center;
  padding: 5px;
}
.logo_img {
  height: 32px;
  margin-right: 5px;
}
.logo_text {
  color: #fff;
  font-size: 17px;
  margin: 0 0 0 12 px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  vertical-align: middle;
}
</style>
