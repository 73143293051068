<template>
  <div>
    <a-layout>
      <a-layout-sider>
        <Menu></Menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header>
          <Header></Header>
        </a-layout-header>
        <a-layout-content>
          <div class="content">
            <router-view></router-view>
          </div>
        </a-layout-content>
        <!-- <a-layout-footer>Footer</a-layout-footer> -->
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import Header from './Header.vue'
import Menu from './Menu.vue'
export default {
  components: { Menu, Header }
}
</script>

<style scoped>
.ant-layout-header {
  background: none;
  padding: 0;
}
.content {
  margin: 8px;
}
</style>
