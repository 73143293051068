
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue'
import {
  LogoutOutlined,
  SettingOutlined,
  KeyOutlined
} from '@ant-design/icons-vue'
import { LOGOUT } from '../api/api'
import router from '@/router'
interface MenuInfo {
  key: string
  keyPath: string[]
  item: unknown
  domEvent: MouseEvent
}
export default {
  components: {
    LogoutOutlined,
    SettingOutlined,
    KeyOutlined
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const nickname = ref()
    onMounted(() => {
      nickname.value = getCookie('nickname')
    })
    const avatarClick = ({ key }: MenuInfo) => {
      if (key === '1') {
        // 修改密码
      } else if (key === '2') {
        // 个人设置
      } else if (key === '3') {
        // 退出登录
        LOGOUT()
        // 跳转登录页面
        router.push('/login')
      }
    }
    // 获取cookie
    function getCookie(name: string) {
      var arr
      var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      if ((arr = document.cookie.match(reg))) {
        return arr[2]
      } else {
        return null
      }
    }
    return {
      nickname,
      avatarClick
    }
  }
}
